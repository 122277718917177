<template>
  <div class="d-grid align-items-center">
    <div class="login-box">
      <div class="white-l-box text-center">
        <img src="/assets/img/logo-big.png" alt="" />
        <h2 class="bold-19 text-gray mt-2 mb-4">{{ $t("forgottenPassword.prodyflow") }}</h2>
        <h2 class="bold-24 text-black mt-3">{{ $t(`integrationAuth.connectProdyflowTo`, {type: type}) }}</h2>
        <div class="error my-2" v-if="error">{{ error }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import http from "../../modules/http";

export default {
  name: "IntegrationAuth",
  data() {
    return {};
  },
  computed: {
    type() {
      const route = useRoute();
      return route.params.type;
    },
  },
  mounted() {
    http
      .fetch(
        "/integrations/auth/" +
          this.type +
          "?" +
          window.location.search.substr(1)
      )
      .then((data) => {
        console.log(data);
      });
  },
};
</script>
